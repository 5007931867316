import '@vueup/vue-quill/dist/vue-quill.snow.prod.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.prod.css';
import 'jodit/build/jodit.min.css'

import 'clockwork-browser/metrics'



import './bootstrap';
import '../css/app.css';
import dayjs from "dayjs";

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import ganttastic from '@infectoone/vue-ganttastic'
import { i18nVue } from 'laravel-vue-i18n'
import 'vuefinder/dist/style.css'
import VueFinder from 'vuefinder/dist/vuefinder'
import { plugin as Slicksort } from 'vue-slicksort';
import { QuillEditor } from '@vueup/vue-quill'
import JoditVue from 'jodit-vue'


const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import { getActiveLanguage } from 'laravel-vue-i18n';

dayjs.extend(relativeTime)
// dayjs.locale(getActiveLanguage())

import * as Sentry from "@sentry/vue";

createInertiaApp({
    progress: {
        color: '#DB2777',
        showSpinner: true
    },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .component('QuillEditor', QuillEditor)
            .use(plugin)
            .use(JoditVue)
            .use(ZiggyVue, Ziggy)
            .use(ganttastic)
            .use(VueFinder)
            .use(Slicksort)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })

        if(import.meta.env.VITE_SENTRY_DSN_PUBLIC instanceof String){
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            });
        }


        app.config.globalProperties.$filters = {
            currency(value) {
                return new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(value);
            },
            dayjs(date) {
                dayjs.locale(getActiveLanguage().split('_')[0])
                return dayjs(date)
            },
            highlight(word, query) {
                const check = new RegExp(query, "ig");
                return word.toString().replace(check, function(matchedText,a,b){
                    return ('<strong class="highlight">' + matchedText + '</strong>');
                });
            }
        }

        app.mount(el);


        return app


    },
});

InertiaProgress.init({ color: '#DB2777' });
